import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus, faUsers, faUserShield, faPencilAlt, 
  faCheck, faEllipsisV, faEye, faEyeSlash 
} from '@fortawesome/free-solid-svg-icons';
import NewUserModal from './NewUserModal';
import './UsersView.css';

/**
 * UsersView Component
 * 
 * This component renders the user management interface.
 * It displays a list of users, allows filtering between active users and admins,
 * and provides functionality to add, edit, and manage user accounts.
 */
const UsersView = () => {
  // State for managing the active tab (active users or admins)
  const [activeTab, setActiveTab] = useState('active');
  
  // State for controlling the visibility of the new user modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // State for storing all users
  const [users, setUsers] = useState([]);
  
  // State for storing filtered users based on the active tab
  const [filteredUsers, setFilteredUsers] = useState([]);
  
  // State for managing loading state
  const [isLoading, setIsLoading] = useState(true);
  
  // State for storing any error or success messages
  const [message, setMessage] = useState(null);

  // State to track which user is being edited
  const [editingUser, setEditingUser] = useState(null);

  // State to store the edited data for a user
  const [editedData, setEditedData] = useState({});

  // State to manage password visibility for each user
  const [showPassword, setShowPassword] = useState({});

  // State to manage loading state when adding a new user
  const [isAddingUser, setIsAddingUser] = useState(false);

  // Effect hook to fetch users when the component mounts
  useEffect(() => {
    console.log('UsersView useEffect triggered');
    fetchUsers();
  }, []);

  /**
   * Fetches users from the backend using the getUsersForManagement endpoint
   */
  const fetchUsers = async () => {
    console.log('Fetching users...');
    setIsLoading(true);
    try {
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'get_users_for_management' }),
      });
      const data = await response.json();
      console.log('Fetched data:', data);
      if (response.ok && data.status === 200) {
        const fetchedUsers = data.users;
        setUsers(fetchedUsers);
        filterUsers(fetchedUsers, activeTab);
      } else {
        console.error('Failed to fetch users:', data.message);
        setMessage({ type: 'error', text: 'Failed to fetch users: ' + (data.message || 'Unknown error') });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setMessage({ type: 'error', text: 'Error fetching users: ' + (error.message || 'Unknown error') });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Filters users based on the active tab
   * 
   * @param {Array} allUsers - Array of all users
   * @param {string} tab - Current active tab ('active' or 'admins')
   */
  const filterUsers = (allUsers, tab) => {
    if (tab === 'active') {
      setFilteredUsers(allUsers.filter(user => user.role === 'student'));
    } else if (tab === 'admins') {
      setFilteredUsers(allUsers.filter(user => user.role === 'admin'));
    } else {
      setFilteredUsers(allUsers);
    }
  };

  /**
   * Handles adding a new user
   * 
   * @param {Object} userData - Data of the new user to be added
   */
  const handleAddUser = async (userData) => {
    console.log('New user data:', userData);
    setMessage(null);
    setIsAddingUser(true);
    try {
      const response = await fetch('/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          action: 'create_user',
          ...userData
        }),
      });
      
      const data = await response.json();
      
      if (response.ok && data.status === 201) {
        // Simulate a delay of 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        setIsModalOpen(false);
        // Fetch the updated user list
        await fetchUsers();
        setMessage({ type: 'success', text: 'User created successfully!' });
        setTimeout(() => setMessage(null), 3000);
      } else {
        setMessage({ type: 'error', text: 'Failed to create user: ' + (data.message || 'Unknown error') });
      }
    } catch (error) {
      console.error('Error creating user:', error);
      setMessage({ type: 'error', text: 'Error creating user: ' + (error.message || 'Unknown error') });
    } finally {
      setIsAddingUser(false);
    }
  };

  /**
   * Handles sidebar menu clicks
   * 
   * @param {string} tab - The tab that was clicked
   */
  const handleMenuClick = (tab) => {
    setActiveTab(tab);
    filterUsers(users, tab);
  };

  /**
   * Initiates editing mode for a user
   * 
   * @param {Object} user - The user to be edited
   */
  const handleEdit = (user) => {
    setEditingUser(user.user_id);
    setEditedData(user);
  };

  /**
   * Saves the edited user data
   * 
   * @param {string} userId - The ID of the user being edited
   */
  const handleSave = async (userId) => {
    // TODO: Implement API call to update user details
    console.log('Saving user:', editedData);
    // For now, just update the local state
    setUsers(users.map(user => user.user_id === userId ? editedData : user));
    setEditingUser(null);
  };

  /**
   * Handles input changes for editable fields
   * 
   * @param {Event} e - The input change event
   * @param {string} field - The field being edited
   */
  const handleInputChange = (e, field) => {
    setEditedData({ ...editedData, [field]: e.target.value });
  };

  /**
   * Toggles password visibility for a user
   * 
   * @param {string} userId - The ID of the user whose password visibility is being toggled
   */
  const togglePasswordVisibility = (userId) => {
    setShowPassword(prev => ({ ...prev, [userId]: !prev[userId] }));
  };

  return (
    <div className="users-view">
      {/* Sidebar for user management options */}
      <div className="users-sidebar">
        {/* New account button */}
        <button 
          className="new-account-btn"
          onClick={() => setIsModalOpen(true)}
        >
          <FontAwesomeIcon icon={faPlus} /> New account
        </button>
        
        {/* Active accounts button */}
        <button 
          className={`sidebar-btn ${activeTab === 'active' ? 'active' : ''}`}
          onClick={() => handleMenuClick('active')}
        >
          <FontAwesomeIcon icon={faUsers} /> Active accounts
        </button>
        
        {/* Admins button */}
        <button 
          className={`sidebar-btn ${activeTab === 'admins' ? 'active' : ''}`}
          onClick={() => handleMenuClick('admins')}
        >
          <FontAwesomeIcon icon={faUserShield} /> Admins
        </button>
      </div>
      
      {/* Main content area */}
      <div className="users-content">
        <h2>Users Management</h2>
        
        {/* Display message if there's one */}
        {message && <p className={`message ${message.type}`}>{message.text}</p>}
        
        {/* Display loading message when fetching data */}
        {isLoading && <p>Loading users...</p>}
        
        {/* Display user table when data is loaded */}
        {!isLoading && (
          <table className="users-table">
            <thead>
              <tr>
                <th>Contact name</th>
                <th>Username</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Password</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.user_id}>
                  <td>
                    {editingUser === user.user_id ? (
                      <input
                        value={editedData.contact_name || ''}
                        onChange={(e) => handleInputChange(e, 'contact_name')}
                      />
                    ) : (
                      `${user.contact_name} ${user.contact_surname}`
                    )}
                  </td>
                  <td>{user.user_id}</td>
                  <td>
                    {editingUser === user.user_id ? (
                      <input
                        value={editedData.student_full_name || ''}
                        onChange={(e) => handleInputChange(e, 'student_full_name')}
                      />
                    ) : (
                      user.student_full_name
                    )}
                  </td>
                  <td>
                    {editingUser === user.user_id ? (
                      <input
                        value={editedData.email || ''}
                        onChange={(e) => handleInputChange(e, 'email')}
                      />
                    ) : (
                      user.email
                    )}
                  </td>
                  <td>
                    {editingUser === user.user_id ? (
                      <select
                        value={editedData.role || ''}
                        onChange={(e) => handleInputChange(e, 'role')}
                      >
                        <option value="student">Student</option>
                        <option value="admin">Admin</option>
                      </select>
                    ) : (
                      user.role
                    )}
                  </td>
                  <td>
                    {editingUser === user.user_id ? (
                      <div className="password-input">
                        <input
                          type={showPassword[user.user_id] ? 'text' : 'password'}
                          value={editedData.password || ''}
                          onChange={(e) => handleInputChange(e, 'password')}
                          placeholder="New password"
                        />
                        <button
                          className="password-toggle"
                          onClick={() => togglePasswordVisibility(user.user_id)}
                        >
                          <FontAwesomeIcon icon={showPassword[user.user_id] ? faEyeSlash : faEye} />
                        </button>
                      </div>
                    ) : (
                      '********'
                    )}
                  </td>
                  <td>
                    {editingUser === user.user_id ? (
                      <button className="action-btn" onClick={() => handleSave(user.user_id)} title="Save changes">
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    ) : (
                      <button className="action-btn" onClick={() => handleEdit(user)} title="Edit user">
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    )}
                    <button className="action-btn" title="More options">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        
        {/* Display message when there are no users */}
        {!isLoading && filteredUsers.length === 0 && (
          <p>No users found.</p>
        )}
      </div>
      
      {/* New User Modal */}
      <NewUserModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddUser={handleAddUser}
      />

      {/* Add a loading overlay */}
      {isAddingUser && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Adding user...</p>
        </div>
      )}
    </div>
  );
};

export default UsersView;